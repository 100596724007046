@import 'variables';
@import '~ui-library_ml/src/components/carousel/styles/paged';
@import '~ui-library_ml/src/components/carousel/styles/arrows';
@import '~ui-library_ml/src/components/carousel-draggable/styles/draggable';

.hub__carousel {
  border: 0;
  margin: rem($andes-spacing-20) auto;
  max-height: rem(200px);
  max-width: rem($boxed-max-width);
  outline: 0;
  overflow: hidden;
  padding: rem($andes-spacing-20) 0;
  vertical-align: baseline;

  @media (min-width: $screen-mobile) {
    overflow: hidden;
    padding: rem(30px) 0;
  }

  .carousel__ul,
  .carousel__brands-ul {
    margin: 0 auto;
    max-width: $carousel-width;
    position: relative;
    text-align: center;

    @media (max-width: $screen-mobile) {
      min-width: 100%;
    }

    .slick-track > .slick-slide {
      @media (min-width: $screen-mobile) {
        display: flex;
        justify-content: center;
        width: $carousel-width / 8;
      }
    }

    .carousel-mobile-list {
      margin: 0 auto;

      &-element {
        padding-bottom: rem($andes-spacing-12);
        vertical-align: top;

        &:first-child {
          margin-left: rem($andes-spacing-4);
        }
      }
    }

    .carousel__item {
      height: auto;
      margin: 0 rem($andes-spacing-8);
      width: rem(88px);

      @media (min-width: $screen-mobile) {
        width: 100px;
      }

      &-img {
        background-color: $andes-white;
        box-sizing: border-box;
        height: rem(88px);
        overflow: hidden;
        padding: rem(10px);
        position: relative;
        text-align: center;
        z-index: 1;

        @media (min-width: $screen-mobile) {
          height: 100px;
        }

        img {
          display: inline;
          width: 100%;
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  .carousel__ul--square,
  .carousel__brands-ul--square {
    .carousel__item-img {
      border-radius: 0;
    }
  }

  .carousel__ul--circle,
  .carousel__brands-ul--circle {
    .carousel__item-img {
      border-radius: 50%;
    }
  }

  // SPECIFIC CATEGORY STYLES
  .carousel__ul {
    .carousel__item-title {
      color: $ml-gray-dark;
      font-size: $subtitle-size - 4;
      font-weight: $fw-regular;
      line-height: 1.2;
      margin: 0;
      padding-top: rem($andes-spacing-12);
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      white-space: normal;

      @media (min-width: $screen-mobile) {
        padding-top: rem($andes-spacing-16);
      }
    }
  }

  // SPECIFIC BRAND STYLES
  .carousel__brands-ul {
    @media (max-width: $screen-mobile) {
      margin: 0 auto;
      max-width: $carousel-width;
      padding: 0;
      position: relative;
      text-align: center;
    }

    .carousel__item-img {
      align-items: center;
      display: flex;
    }

    .carousel__item {
      display: inline-block;
      height: auto;
      margin: 0 rem($andes-spacing-12);
    }

    .carousel__item-title {
      display: none;

      @media (min-width: $screen-mobile) {
        display: none;
      }
    }
  }

  .carousel-container {
    margin: 0 auto;

    .prev-button,
    .next-button {
      background: 0 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      color: inherit;
      display: block;
      font: inherit;
      height: rem(40px);
      padding: 0 !important;
      top: 25%;
      user-select: none;
      width: rem(40px);

      &:hover {
        box-shadow: none;
      }

      &:before,
      &:after {
        background-color: $ml-gray-darker-2;
        height: rem(1px);
        margin-bottom: rem(-$andes-spacing-8);
        width: rem(16px);
      }
    }

    .prev-button {
      left: rem(-36px);
    }

    .next-button {
      right: rem(-36px);
    }
  }

  div.slick-track {
    white-space: nowrap;

    .slick-slide.slick-active.slick-cloned {
      width: rem(136.75px);
    }
  }

  div[role='presentation'].slick-list {
    height: auto;
  }

  ul.slick-dots {
    bottom: auto;
    height: rem($andes-spacing-12);
    position: relative;
    visibility: hidden;
  }

  .title {
    color: $ml-gray-darker-2;
    display: block;
    font-family: 'Proxima Nova';
    font-size: $subtitle-size;
    font-weight: $fw-regular;
    letter-spacing: 1.5px;
    margin: 0;
    padding: 0 rem(10px) rem(25px);
    text-align: center;
    text-transform: uppercase;

    @media (min-width: $screen-mobile) {
      padding-bottom: rem($andes-spacing-24);
    }
  }
}
